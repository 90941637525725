import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  TorusKnotBufferGeometry,
  ShaderMaterial,
  Mesh,
  Vector2,
  Color,
  Clock
} from 'three';

import vertexShader from './test/water/vertex.glsl';
import fragmentShader from './test/water/fragment.glsl';

export const Website = () => {
  const debugObject = {
    depthColor: '#cc6691',
    surfaceColor: '#FFAA91'
  };

  const debugObject2 = {
    depthColor: '#186691',
    surfaceColor: '#9bd8ff'
  };

  const scene = new Scene();
  const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100);
  const renderer = new WebGLRenderer();

  renderer.setSize(window.innerWidth, window.innerHeight);
  camera.position.set(0, 0.6, 2);
  scene.add(camera);

  const geometry = new TorusKnotBufferGeometry(0.5, 0.01, 512, 512);

  const material = new ShaderMaterial({
    vertexShader,
    fragmentShader,
    uniforms: {
      uTime: {
        value: 0.5
      },

      uBigWaveSpeed: {
        value: 0.0
      },
      uBigWavesElevation: {
        value: 0.0
      },
      uBigWavesFrequency: {
        value: new Vector2(4, 1.5)
      },

      uDepthColor: {
        value: new Color(debugObject.depthColor)
      },
      uSurfaceColor: {
        value: new Color(debugObject.surfaceColor)
      },

      uColorOffset: { value: 0.08 },
      uColorMultiplier: { value: 5 },
      opacity: { value: 1.0 },
      offsetUp: { value: 1.0 }
    }
  });
  const mesh = new Mesh(geometry, material);
  mesh.rotation.x = Math.PI * 0.4;
  scene.add(mesh);

  const geometry2 = new TorusKnotBufferGeometry(1.8, 0.5, 512, 512);

  const material2 = new ShaderMaterial({
    transparent: true,
    vertexShader,
    fragmentShader,
    uniforms: {
      uTime: {
        value: 0.0
      },

      uBigWaveSpeed: {
        value: 0.75
      },
      uBigWavesElevation: {
        value: 0.5
      },
      uBigWavesFrequency: {
        value: new Vector2(20, 10.5)
      },

      uDepthColor: {
        value: new Color(debugObject2.depthColor)
      },
      uSurfaceColor: {
        value: new Color(debugObject2.surfaceColor)
      },

      uColorOffset: { value: 0.08 },
      uColorMultiplier: { value: 5 },
      opacity: { value: 0.1 },

      offsetUp: { value: 0.0 }
    }
  });

  const mesh2 = new Mesh(geometry2, material2);
  mesh2.rotation.x = Math.PI * 0.4;
  mesh2.rotation.z = Math.PI * 0.4;
  scene.add(mesh2);
  window.addEventListener('resize', () => {
    // Update camera
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  });

  let drawVisual;
  Micca(material2, drawVisual, material);

  const clock = new Clock();

  const animate = () => {
    const elapsedTime = clock.getElapsedTime();
    material.uniforms.uTime.value = elapsedTime;
    material2.uniforms.uTime.value = elapsedTime;
    mesh.rotation.z -= 0.005;

    // controls.update();
    renderer.render(scene, camera);
    requestAnimationFrame(animate);
  }
  animate();

  document.getElementById('bg').appendChild(renderer.domElement);
  document.body.classList.add('loaded');
};

const lerp = (a, b, n) => (1 - n) * a + n * b;

const Micca = (material2, drawVisual, material) => {
  const gotAudio = stream => {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const source = audioCtx.createMediaStreamSource(stream);
    const analyser = audioCtx.createAnalyser();
    source.connect(analyser);

    analyser.fftSize = 32;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    const max = 255 * bufferLength;

    const clamp = (x, max, amp) => (x / max) * amp;
    const normalize = (x, istart, istop, ostart, ostop) => {
      return ostart + (ostop - ostart) * ((x - istart) / (istop - istart));
    };
    const draw = () => {
      drawVisual = requestAnimationFrame(draw);
      analyser.getByteFrequencyData(dataArray);
      let x = 0;
      let y = 0;
      let z = 0;
      let w = 0;

      for (let i = 0; i < bufferLength; i++) {
        if (i < 4) {
          x += dataArray[i];
        } else if (i < 8) {
          y += dataArray[i];
        } else if (i < 12) {
          z += dataArray[i];
        } else if (i < 16) {
          w += dataArray[i];
        }
      }

      material2.uniforms.offsetUp.value = lerp(material2.uniforms.offsetUp.value, clamp(x + y + z + w, max, 1.0) * -1.0, 0.6);
      material2.uniforms.uColorOffset.value = lerp(material2.uniforms.uColorOffset.value, normalize(z, 0, 1000, 0.08, 0.3), 0.6);
      material2.uniforms.uColorMultiplier.value = lerp(material2.uniforms.uColorMultiplier.value, normalize(w, 0, 1000, 5, 10), 0.6);

      material.uniforms.uBigWavesElevation.value = lerp(material.uniforms.uBigWavesElevation.value, normalize(z, 0, 1000, 0.0, 1.0), 0.6);
      material.uniforms.offsetUp.value = lerp(material.uniforms.offsetUp.value, clamp(x + y + z + w, max, 2.0) * -1.0, 0.6);
      material.uniforms.uColorMultiplier.value = normalize(w, 0, 1000, 5, 10);
    };

    draw();
  };

  navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    .then(gotAudio);
};

Website();